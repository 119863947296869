import Head from "next/head";
import { SessionProvider } from "next-auth/react";
import Router from "next/router";
import { Toaster } from "react-hot-toast";
import nProgress from "nprogress";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
    SettingsConsumer,
    SettingsProvider,
} from "../contexts/settings-context";
import { createTheme } from "../theme";
import { createEmotionCache } from "../utils/create-emotion-cache";

import { AuthGuard } from "../components/authentication/AuthGuard";

Router.events.on("routeChangeStart", nProgress.start);
Router.events.on("routeChangeError", nProgress.done);
Router.events.on("routeChangeComplete", nProgress.done);

const clientSideEmotionCache = createEmotionCache();

const App = (props) => {
    const {
        Component,
        emotionCache = clientSideEmotionCache,
        pageProps,
    } = props;

    const getLayout = Component.getLayout ?? ((page) => page);

    return (
        <SessionProvider session={pageProps.session}>
            <CacheProvider value={emotionCache}>
                <Head>
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <meta
                        name="viewport"
                        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                    />
                    <link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        href="/favicons/apple-touch-icon.png"
                    />
                    <link rel="icon" href="/favicons/favicon.ico" />
                    <meta name="theme-color" content="#111827" />
                    <meta charSet="utf-8" />
                    <meta name="author" content="TCA Developments" />
                    <meta
                        name="description"
                        content="Admin dashboard for TCA Developments. Built with care and coffee ☕️ by https://tristandeane.ca"
                    />
                    <meta
                        name="keywords"
                        content="tca,developments,application,dashboard,admin"
                    />
                    <link rel="manifest" href="/manifest.json" />
                    <link
                        rel="/favicons/apple-touch-icon"
                        href="/favicons/apple-icon.png"
                    />
                    <meta name="theme-color" content="#317EFB" />
                    <title>TCA Developments</title>
                </Head>
                <SettingsProvider>
                    <SettingsConsumer>
                        {({ settings }) => (
                            <ThemeProvider
                                theme={createTheme({
                                    direction: settings.direction,
                                    responsiveFontSizes:
                                        settings.responsiveFontSizes,
                                    mode: settings.theme,
                                })}
                            >
                                <CssBaseline />
                                <Toaster position="top-center" />
                                {Component.public ? (
                                    getLayout(<Component {...pageProps} />)
                                ) : (
                                    <AuthGuard>
                                        {getLayout(
                                            <Component {...pageProps} />
                                        )}
                                    </AuthGuard>
                                )}
                            </ThemeProvider>
                        )}
                    </SettingsConsumer>
                </SettingsProvider>
            </CacheProvider>
        </SessionProvider>
    );
};

export default App;
