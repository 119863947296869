import { useSession } from "next-auth/react";

import { SplashScreen } from "../splash-screen";

export const AuthGuard = ({ children }) => {
    // if `{ required: true }` means `status` can only be "loading" or "authenticated"
    const { status } = useSession({ required: true });

    // getting credentials, show loading state
    if (status === "loading") {
        return <SplashScreen />;
    }

    // user valid, show page
    return children;
};
