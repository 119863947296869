import Image from "next/image";

import WhiteLogo from "../../public/static/tca-white.png";
import TCALogo from "../../public/static/tca-logo.png";

export const Logo = (props) => {
    return props.showdark ? (
        <Image src={TCALogo} {...props} />
    ) : (
        <Image src={WhiteLogo} {...props} />
    );
};
